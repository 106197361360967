import React from 'react';
import Account from '../../components/Account';
import PrivateRoute from '../../components/PrivateRoute';
import ContextProvider from '../../components/ContextProvider';

const AccountPage = ({ localeCode }: { localeCode?: string }) => (
  <ContextProvider localeCode={localeCode}>
    <PrivateRoute path="/account" component={Account} />
  </ContextProvider>
);

export default AccountPage;
